<template>
  <!-- <loader :active="loaderActive" message="Please wait 5 seconds" /> -->
  <div class="card">
    <Toast />
    <form>
      <div class="p-grid p-fluid">
        <div class="p-col-12">
          <h5>{{ $t('Applications.AddApplication.Heading') }}</h5>
          <div class="p-grid p-formgrid p-mb-3">
            <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
              <label for="title2">{{ $t('Applications.AddApplication.Title') }}</label>
              <InputText type="text" :placeholder="$t('Applications.AddApplication.TitlePlace')" id="title2"
                v-model="title" @keydown.space="preventLeadingSpace" :class="`${error.title ? 'p-invalid' : ''}`"
                maxlength="100"></InputText>
              <div class="text-red">{{ error.title }}</div>
            </div>
          </div>

          <div class="p-grid p-formgrid p-mb-3">
            <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
              <label for="title2">{{ $t('Applications.AddApplication.Description') }}</label>
              <Textarea type="text" :placeholder="$t('Applications.AddApplication.DescriptionPlace')" id="title2"
                v-model="description" @keydown.space="preventLeadingSpace"
                :class="`${error.description ? 'p-invalid' : ''}`" maxlength="1500"></Textarea>
              <div class="text-red">{{ error.description }}</div>
            </div>
          </div>

          <div class="p-grid p-formgrid p-mb-3">
            <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
              <label for="title2">{{ $t('Applications.AddApplication.MaxApplicants') }}</label>
              <InputText type="text" :placeholder="$t('Applications.AddApplication.MaxApplicantsPlace')" id="title2"
                @keydown.space="preventLeadingSpace" v-model="max_user_apply"
                :class="`${error.max_user_apply ? 'p-invalid' : ''}`" onkeypress="return /[0-9]/i.test(event.key)"
                onkeyup="if (event.srcElement.value.charAt(0) == '0') { event.srcElement.value = event.srcElement.value.slice(1); }"
                maxlength="7">
              </InputText>
              <div class="text-red">{{ error.max_user_apply }}</div>
            </div>
          </div>

          <div class="p-grid p-formgrid p-mb-3">
            <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
              <label for="title2">{{ $t('Applications.AddApplication.Country') }}</label>
              <Dropdown v-model="country_id" :options="dropdownCountryValues" optionValue="id"
                optionLabel="country_name" :placeholder="$t('Applications.AddApplication.Select')"
                :class="`${error.country_id ? 'p-invalid' : ''}`" />
              <div class="text-red">{{ error.country_id }}</div>
              <!-- <div class="text-red">{{ error.country_id }}</div> -->
            </div>
          </div>

          <div class="p-grid p-formgrid p-mb-3">
            <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
              <label for="subtitle2">
                {{ $t('Applications.AddApplication.Upload') }}

                <!-- <span class="img-info">(파일 형식: jpg,jpeg,png )(이미지 너비 924px and 고저 860px 및 크기 1MB )</span> -->
              </label>
              <div :class="`${error.file ? 'custom-select-invalid' : 'custom-select'}`">
                <span v-if="!fileName">{{ $t('Applications.AddApplication.ChooseFile') }}</span>
                <span v-else>{{ fileName }}</span>
                <input type="file" class="select-file" v-on:change="onFileChange" />
                <Button :label="$t('Applications.AddApplication.fileupload')" class="SelectBtn n-wrap" id="choose" />
              </div>
              <div class="text-red" v-show="render2">최대 파일 크기는 5MB여야 합니다.</div>
              <div class="text-red">{{ error.file }}</div>
              <div class="text-red" v-show="render1">유효하지 않은 파일 형식</div>
              <img id="frame" src width="100px" height="100px" />
            </div>
          </div>

          <div class="p-grid p-formgrid p-mb-3">
            <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
              <label for="title2">{{ $t('Applications.AddApplication.ApplicationStartDate') }}</label>
              <Calendar :showIcon="true" :showButtonBar="true" dateFormat="yy.mm.dd" placeholder="YYYY/MM/DD H:M:S"
                v-model="release_date" :class="`${error.release_date ? 'p-invalid' : ''}`" :min-date="new Date()"
                :showTime="true" :showSeconds="true" @date-select="GetApplicationStartstamp(release_date)"></Calendar>
              <div class="text-red">{{ error.release_date }}</div>
            </div>
          </div>

          <div class="p-grid p-formgrid p-mb-3">
            <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
              <label for="title2">{{ $t('Applications.AddApplication.ApplicationEndDate') }}</label>
              <Calendar :showIcon="true" :showButtonBar="true" dateFormat="yy.mm.dd" placeholder="YYYY/MM/DD H:M:S"
                v-model="expire_date" :class="`${error.expire_date ? 'p-invalid' : ''}`" :min-date="release_date"
                :showTime="true" :showSeconds="true" :disabled="!release_date"
                @date-select="GetApplicationEndstamp(expire_date)">
              </Calendar>
              <div class="text-red">{{ error.expire_date }}</div>
            </div>
          </div>

          <div class="p-grid p-formgrid p-mb-3">
            <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
              <label for="title2">{{ $t('Applications.AddApplication.VotingstartDate') }}</label>
              <Calendar :showIcon="true" :showButtonBar="true" dateFormat="yy.mm.dd" placeholder="YYYY/MM/DD H:M:S"
                v-model="voting_start_date" :class="`${error.voting_start_date ? 'p-invalid' : ''}`"
                :min-date="expire_date" :showTime="true" :showSeconds="true" :disabled="!expire_date"
                @date-select="GetVotingStartstamp(voting_start_date)"></Calendar>
              <div class="text-red">{{ error.voting_start_date }}</div>
            </div>
          </div>

          <div class="p-grid p-formgrid p-mb-3">
            <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
              <label for="title2">{{ $t('Applications.AddApplication.VotingEndDate') }}</label>
              <Calendar :showIcon="true" :showButtonBar="true" dateFormat="yy.mm.dd" placeholder="YYYY/MM/DD H:M:S"
                v-model="voting_end_date" :class="`${error.voting_end_date ? 'p-invalid' : ''}`"
                :min-date="voting_start_date" :showTime="true" :showSeconds="true" :disabled="!voting_start_date"
                @date-select="GetVotingEndstamp(voting_end_date)">
              </Calendar>
              <div class="text-red">{{ error.voting_end_date }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-d-flex p-jc-end p-ai-center" style="float: left">
        <Button :label="$t('Applications.AddApplication.Save')" icon="pi pi-check" iconPos="left"
          class="p-button p-button-sm p-mr-2 p-mb-2" @click="AddVoting"></Button>
      </div>

      <div class="p-d-flex p-jc-end p-ai-center">
        <div>
          <Button :label="$t('Applications.AddApplication.Back')" icon="pi pi-replay" iconPos="left"
            class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="$router.go(-1)"></Button>
        </div>
      </div>

      <br />
    </form>
  </div>
</template>
<script>
// import Loader from '../../../components/Loader.vue'
import axios from 'axios';
import VotemanagementService from '../../../service/API/SuperAdmin/VotemanagementService.js';
import ValidateAddVote from '../../../validations/VoteManagement/ValidateAddVote';
import { useRoute } from 'vue-router';
import moment from 'moment';

export default {
  // components: {

  // },
  data() {
    return {
      bulletinCategoryDetail: {},
      title: '',
      country_id: '',
      max_user_apply: '',
      image: '',
      description: '',
      release_date: '',
      expire_date: '',
      voting_start_date: '',
      voting_end_date: '',
      status: 'active',

      file: '',
      files: '',
      fileName: '',
      fileExtension: '',
      filesExtension: '',
      fileExtensions: '',
      filesExtensions: '',
      dropdownCountryValues: '',
      dropdownCountryValue: null,
      loaderActive: false,
      render2: false,
      render1: false,
      expose: '',

      // status:'',

      formData: new FormData(),
      error: {},
    };
  },
  created() {
    this.votemanagementService = new VotemanagementService();
  },
  watch: {
    release_date(value) {
      if (this.release_date > this.expire_date) {
        this.expire_date = ''
      } else {
        this.expire_date = value;
      }
    },
    expire_date(value) {
      if (this.expire_date > this.voting_start_date) {
        this.voting_start_date = ''
      } else {
        this.voting_start_date = value;
      }
    },
    voting_start_date(value) {
      if (this.voting_start_date > this.voting_end_date) {
        this.voting_end_date = ''
      } else {
        this.voting_end_date = value;
      }
    }
  },

  mounted() {
    const route = useRoute();
    console.log(route.params);
    this.votemanagementService
      .getCountrydropdown()
      .then((data) => {
        this.dropdownCountryValues = data.data.data.country;
        // this.products = data;
        this.loading1 = false;
        // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));
        console.log(this.dropdownCountryValues);
      })
      .catch((err) => console.log(err));
    // this.UserComments(this.$route.params.id)
  },
  methods: {
    FormatDate(value) {
      if (value) {
        return moment(String(value)).format('YYYY/MM/DD  h:mm:ss');
      }
    },
    FormatDateAdd(value) {
      if (value) {
        return moment(value).format('YYYY-MM-DD HH:mm:ss');
      }
    },
    preventLeadingSpace(e) {
      // only prevent the keypress if the value is blank
      if (!e.target.value) e.preventDefault();
      // otherwise, if the leading character is a space, remove all leading white-space
      else if (e.target.value[0] == ' ') e.target.value = e.target.value.replace(/^\s*/, "");
    },
    GetApplicationStartstamp(release) {
      release = new Date(this.release_date);
      let timestamp1 = release.getTime();
      // alert(timestamp1)
      console.log(timestamp1)
    },
    GetApplicationEndstamp(expire) {
      expire = new Date(this.expire_date);
      let timestamp2 = expire.getTime();
      // alert(timestamp2)
      console.log(timestamp2)
    },
    GetVotingStartstamp(vote_start) {
      vote_start = new Date(this.voting_start_date);
      let timestamp3 = vote_start.getTime();
      // alert(timestamp3)
      console.log(timestamp3)
    },
    GetVotingEndstamp(vote_end) {
      vote_end = new Date(this.voting_end_date);
      let timestamp4 = vote_end.getTime();
      // alert(timestamp4)
      console.log(timestamp4)
    },


    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      // if (!files.length) return;
      this.file = files[0];
      var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      if (!allowedExtensions.exec(this.file.name)) {
        this.render1 = true;
        this.render2 = false;
      } else {
        let reader = new FileReader
        reader.readAsDataURL(this.file)
        reader.onload = (e) => {
          let url = e.target.result
          this.image_url = url
        }
        this.render1 = false;
        if (this.file.size > 5242880) {
          // alert('파일이 너무 큽니다  ');
          this.render2 = true;
        } else {
          this.render2 = false;
          this.fileName = this.file.name;
          this.formData.append('image', files[0]);
          // this.upload(e)
        }
      }
      this.fileExtension = this.fileName.replace(/^.*\./, '');
      console.log(this.fileName);
    },
    // onFileChange(e) {
    //   var files = e.target.files || e.dataTransfer.files;
    //   if (!files.length) return;
    //   this.file = files[0];
    //   var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    //   if (!allowedExtensions.exec(this.file.name)) {
    //     this.render1 = true;
    //     return false;
    //   } else {
    //     this.render1 = false;
    //     this.fileName = this.file.name;
    //     this.formData.append('image', files[0]);
    //   }
    //   this.fileExtension = this.fileName.replace(/^.*\./, '');
    //   console.log(this.fileName);
    // },

    AddVoting() {
      let vcheckData = {
        title: this.title,
        description: this.description,
        // type: this.dropdownValueType == null ? '' : 'something',
        voting_start_date: this.voting_start_date,
        voting_end_date: this.voting_end_date,
        release_date: this.release_date,
        expire_date: this.expire_date,
        max_user_apply: this.max_user_apply,
        country_id: this.country_id.toString(),
        file: this.fileName == '' ? '' : 'something',
      };

      // if (this.FormatDateAdd(this.release_date) >= this.FormatDateAdd(this.expire_date) && this.FormatDateAdd(this.release_date) >= this.FormatDateAdd(this.voting_start_date) && this.FormatDateAdd(this.release_date) >= this.FormatDateAdd(this.voting_end_date)) {
      //   alert('Application StartDate Should Not Greater Than Applicatiion EndDate');
      //   return this.release_date = '';
      // } else if (this.FormatDateAdd(this.expire_date) >= this.FormatDateAdd(this.voting_start_date) && this.FormatDateAdd(this.expire_date) >= this.FormatDateAdd(this.voting_end_date)) {
      //   alert('Application EndDate Should Not Greater Than Voting StartDate');
      //   return this.expire_date = '';
      // } else if (this.FormatDateAdd(this.voting_start_date) >= this.FormatDateAdd(this.voting_end_date)) {
      //   alert('Voting StartDate Should Not Greater Than Voting EndDate');
      //   return this.voting_start_date = '';
      // }
      // else {
      // if (this.timestamp1 >= this.timestamp2 && this.timestamp3 && this.timestamp4) {
      //   console.log("start time should be less");
      //   alert("start time should be less");
      // }
      // else {
      //   if (this.timestamp2 >= this.timestamp3 && this.timestamp4) {
      //     console.log("expiry time should be less");
      //   }
      //   else {
      //     if (this.timestamp3 >= this.timestamp4) {
      //       console.log("vote start time should be less");
      //     }
      //   }
      // }

      const { isInvalid, error } = ValidateAddVote(vcheckData);
      if (isInvalid) {
        this.error = error;
      } else {
        // console.log(this.code);
        this.formData.append('title', this.title);
        this.formData.append('country_id', this.country_id);
        this.formData.append('description', this.description);
        this.formData.append('release_date', this.FormatDateAdd(this.release_date));
        this.formData.append('expire_date', this.FormatDateAdd(this.expire_date));
        this.formData.append('voting_start_date', this.FormatDateAdd(this.voting_start_date));
        this.formData.append('voting_end_date', this.FormatDateAdd(this.voting_end_date));
        this.formData.append('max_user_apply', this.max_user_apply);
        this.formData.append('status', this.status);

        // this.formData.append('type', this.dropdownValueType?.name);
        // this.formData.append('status', this.dropdownValue.name===undefined ? this.dropdownValue :this.dropdownValue.name);
        console.log(this.formData);
        // this.loaderActive = true;
        return axios.post('/asnhist-dev/api/v1/admin/application/add', this.formData).then(() => {
          this.$router.push({ name: 'Votemanagementlist' });
          setTimeout(() => {
            this.$toast.add({ severity: 'info', summary: '확인되었습니다.', detail: '지원서가 성공적으로 추가되었습니다.', life: 3000 });
          }, 1000);
        });
      }
    }
  },
  // }
};
</script>

<style scoped>
.p-fluid .p-button {
  width: auto;
}

.p-button {
  background: #000000;
  border: 1px solid #0a0a0a;
  color: white;
}

.product-image {
  padding-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 150px;
  height: 100px;

}

button#Save {
  margin-left: 13px;
  margin-top: 15px;
}

div#double-text {
  display: flex;
  justify-content: space-between;
}

.custom-select {
  position: relative;
  border: 1px solid #cecece;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
}

.custom-select-invalid {
  position: relative;
  border: 1px solid red;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
}

.select-file {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0%;
  z-index: 5;
  opacity: 0;
}

.SelectBtn {
  max-width: 100px;
}

.custom-select span {
  max-width: 140px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.p-button {
  background: #000000;
  border: 1px solid #0a0a0a;
  color: white;
}
</style>