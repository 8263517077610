
import validator from 'validator';

const ValidateAddVote = (data) => {
    let errors = {};
    const { title,
        country_id,
        max_user_apply,
        description,
        release_date,
        expire_date,
        voting_start_date,
        voting_end_date, } = data;

    if (validator.isEmpty(title)) {
        errors.title = '제목은 필수 항목입니다.';
    }
    if (validator.isEmpty(country_id)) {
        errors.country_id = '국가를 선택해주세요.';
    }
    if (validator.isEmpty(description)) {
        errors.description = '내용은 필수 항목입니다.';
    }
    if (validator.isEmpty(max_user_apply)) {
        errors.max_user_apply = '최대 지원자 수는 필수 항목입니다.';
    }
    if (!release_date) {
        errors.release_date = '지원서 시작일은 필수 항목입니다.';
    } else if(expire_date && release_date >= expire_date){
        errors.expire_date = '지원서 종료일은 시작일 이후여야 합니다.';
    } else if(voting_start_date && release_date >= voting_start_date){
        errors.voting_start_date = '투표 시작일은 지원서 시작일 이후여야 합니다.';
    } else if(voting_end_date && release_date >= voting_end_date){
        errors.voting_end_date = '투표 종료일은 지원서 시작일 이후여야 합니다.';
    }
    if (!expire_date) {
        errors.expire_date = '지원서 마감일은 필수 항목입니다.';
    } else if(voting_start_date && expire_date >= voting_start_date){
        errors.voting_start_date = '투표 시작일은 지원서 종료일 이후여야 합니다.';
    } else if(voting_end_date && expire_date >= voting_end_date){
        errors.voting_end_date = '투표 종료일은 지원서 종료일 이후여야 합니다.';
    }
    if (!voting_end_date) {
        errors.voting_end_date = '투표 시작일은 필수 항목입니다.';
    }
    if (!voting_start_date) {
        errors.voting_start_date = '투표 마감일은 필수 항목입니다.';
    }else if(voting_end_date && voting_start_date >= voting_end_date){
        errors.voting_end_date = '투표 종료일은 투표 시작일 이후여야 합니다.';
    }
    // if (validator.isEmpty(file)) {
    //     errors.file = 'File Is Required.';
    // }
    return {
        isInvalid: Object.keys(errors).length > 0,
        error: errors,
    };
};

export default ValidateAddVote;